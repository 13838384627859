
@import "./../scss/required";
@import "./../scss/05-components/progress";

.input-group + .progress {
    height: 2px;
    margin-top: -2px;

    .progress-bar {
        z-index: 3;
        height: 100%;
    }
}
